import { template as template_9d76c1b60c0e4b9d8a80b722fca35d48 } from "@ember/template-compiler";
const FKText = template_9d76c1b60c0e4b9d8a80b722fca35d48(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
