import { template as template_d43bbd3866d341f7997a4ff2a9cd8e64 } from "@ember/template-compiler";
const WelcomeHeader = template_d43bbd3866d341f7997a4ff2a9cd8e64(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
